$primary: #0B65C2;
$secondary: #41463d;
$disabled: #c4c4c4;
$success: #1fc55c;
$danger: #d02a30;
$info: #ffe347;
$warning: #fc8e09;

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  background-color: #e8e8e8d5 !important;
}

.btn {
  border-radius: 25px !important;
  padding: 10px 20px !important;
}

.btn-primary {
  color: white !important;
}

.wrap-container {
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
}

.modal-content {
  border-radius: 15px !important;
  background-color: white !important;
  border: none !important;
}

.wembii-border {
  border-radius: 30px;
  border: 3px solid #e6e6ee;
}

.wembii-border-small {
  border-radius: 15px;
  border: 1px solid #e6e6ee;
}

.whiteButton {
  background-color: white !important;
  color: $secondary !important;
  border: none !important;
}
.disabledButton {
  background-color: $disabled !important;
  color: gray !important;
  cursor: not-allowed;
  border: none !important;
}

.disabledButton:hover {
  background-color: $disabled !important;
  color: gray !important;
  cursor: not-allowed;
}

.wembiiButton {
  background-color: $primary !important;
  color: #fff !important;
  border: none !important;

  a,
  a:hover {
    color: #fff;
    text-decoration: none;
  }
}

.squareButton {
  background-color: #fff !important;
  color: $secondary !important;
  border-radius: 0 !important;
  border: none !important;

  &.selected {
    background-color: #eeeeee !important;
  }
}

.excelButton {
  background-color: transparent !important;
  border: solid 0.5px $primary !important;
  color: $primary !important;
}

.excelButton:hover {
  background-color: $primary !important;
  border: solid 0.5px transparent !important;
  color: white !important;
  a:hover {
    color: white;
    text-decoration: none;
  }
}

.mailchimpButton {
  background-color: #ffe01b !important;
  border: none !important;
  color: $secondary !important;
}

.ballon-right {
  content: "";
  position: absolute;
  bottom: 0;
  right: -7px;
  width: 0px;
  height: 0px;
  border-top: 15px solid #0d4b7e;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  transform: rotate(90deg);
}

.ballon-left {
  content: "";
  position: absolute;
  bottom: 0;
  left: -6px;
  width: 0px;
  height: 0px;
  border-bottom: 15px solid #f8f9fa;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  transform: rotate(90deg);
}

@import "node_modules/bootstrap/scss/bootstrap";

.btn-outline-primary:hover:not(:disabled),
.btn-outline-danger:hover:not(:disabled),
.btn-outline-secondary:hover:not(:disabled),
.chooseFileButton:hover,
.btn-danger:disabled,
.btn-danger {
  color: #fff;
}

.hover-color-primary {
  color: black;
  cursor: pointer;
  &:hover {
    color: $primary;
    font-weight: bold;
  }
}

.hover-color-danger {
  color: black;
  cursor: pointer;
  &:hover {
    color: $danger;
    font-weight: bold;
  }
}

.hubspotButton {
  background-color: #ff6b49;
  border-color: #ff6b49;
  opacity: 0.8;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    opacity: 1;
    background-color: #ff6b49 !important;
    border-color: #ff6b49 !important;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 107, 73, 0.5);
  }
}

.planmodalbadge {
  &.closed {
    width: 30px;
  }
}

.emoji-mart-scroll {
  height: 75%;
}

.btn-ligth:hover {
  background-color: #091e4214;
}

.user-cascade-container {
  .user-avatar {
    position: absolute;
  }

  .avatar-lg-hidden {
    display: none;
  }
}

@media (max-width: 992px) {
  .user-cascade-container {
    .user-avatar {
      position: relative;
      left: 0 !important;
      margin: 2px;
    }

    .avatar-lg-hidden {
      display: block;
    }

    .avatar-md-hidden {
      display: none;
    }
  }
}

.error {
  font-size: 11px;
  color: #ef767a;
}

.borderRed {
  border-color: #ef767a;
}

.option-menu {
  max-height: 250px;
  overflow-y: auto;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 76.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 25;
  height: 100%;
  width: 100%;
  position: absolute;
}

.view-style-buttons {
  .lbutton {
    border-radius: 50px 0 0 50px !important;
  }
  .rbutton {
    border-radius: 0 50px 50px 0 !important;
  }
}