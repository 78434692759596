.imagePreview {
  width: 100px;
  height: 100px;
  border-radius: 15px;
  object-fit: cover;
}

.imageEditor {
  width: 500px;
  max-height: 500px;
  object-fit: cover;
  border-radius: 15px;
}

.imageInstagramPostModal {
  width: 500px;
  height: 300px;
  object-fit: contain;
  border-radius: 15px;
}
