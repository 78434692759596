.calendar {
  display: block;
  position: relative;
  width: 100%;

  .days {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 70%;
    padding: 0.75em 0;
    border-bottom: 1px solid gray;
  }

  .cell {
    position: relative;
    min-height: 100px;
    overflow: hidden;
    transition: 0.25s ease-out;
    padding: 5px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  
    &:hover {
      background: #f9f9f9;
      transition: 0.5s ease-out;
    }

    .number-day {
      position: absolute;
      font-size: 82.5%;
      line-height: 1;
      top: 0.75em;
      right: 0.75em;
      font-weight: 700;
    }
  }

  .disabled {
    background-color: #F2F2F2;
    color: #ccc;
    pointer-events: none;
  }

  .today {
    background-color: #D1E8FF;
  }
}

.react-date-picker__wrapper,
.react-daterange-picker__wrapper {
  border-radius: 10px;
  padding: 4px;
  border: 1px solid #ced4da;
}

.fc {
  .fc-col-header-cell-cushion {
    color: #1a252f;
    text-decoration: none;
  }

  .fc-daygrid-day-number {
    color: #1a252f;
    text-decoration: none;
  }

  .fc-timegrid-event {
    cursor: pointer;
  }

  .fc-daygrid-event {
    color: #1a252f;
    text-decoration: none;
    cursor: pointer;
  }
}