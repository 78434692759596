.miniModal {
  height: 100%;
  &On {
    transition: all 500ms ease-out;
    pointer-events: all;
    transform: scale(1);
  }
  &Off {
    transform: scale(0);
    transition: all 150ms ease-in;
    pointer-events: none;
  }
}
