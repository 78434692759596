.socialProviderIcon {
  position: relative;
  &-icon {
    position: absolute;
    right: -10px;
    bottom: 0;
    width: 30px;
    height: 30px;
  }
  &-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    &-inactive {
      opacity: 0.5;
    }
  }
}
