.social-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 4fr));
  grid-gap: 20px;
  padding: 20px;
}
.social-item {
  height: 100%;
  width: 100%;
  text-align: center;
  justify-content: center;
}
