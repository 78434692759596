.sidebar {
  min-width: 123px;
}

.sidebar-item {
  .group-label,
  .group-icon,
  .item-label,
  .item-icon {
    color: #65676d !important;
  }
}

.sidebar-item.active-group {
  background-color: rgba(32, 186, 236, 0.1);
  margin: 0 -16px;
  padding: 0 16px;
  border-right: 5px solid $primary;

  .group-label,
  .group-icon {
    color: $primary !important;
  }
}

.sidebar-item.active-item {
  .item-label,
  .item-icon {
    color: $primary !important;
  }
}

.disabled {
  .group-label,
  .group-icon,
  .item-label,
  .item-icon {
    color: rgba(108, 117, 125, 0.5) !important;
    pointer-events: none !important;
    cursor: default !important;
  }
}

.sidebarExpanded {
  transition: all 300ms ease-out;
  width: 280px;
}

.sidebarCollaped {
  transition: all 200ms ease-in;
  width: 100px;
}
