.socialCard-avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: contain;

  &-container {
    position: absolute;
    bottom: 0;
    right: 30px;
  }
}

