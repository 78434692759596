.textEditor {
  &-textArea {
    min-height: 220px;
    resize: none;
  }
  &-icon {
    width: 25px;
    height: 25px;
  }
}
