.mainSectionExpanded {
  transition: all 200ms ease-out;
  width: calc(100% - 123px);
}

.mainSectionCollaped {
  transition: all 300ms ease-out;
  width: calc(100% - 300px);
}

@media (max-width: 992px) {
  .mainSection {
    width: 100%;
  }

  .mainSectionCollaped {
    width: calc(100% - 300px);
  }
}

.smooth-dnd-no-user-select, 
.smooth-dnd-disable-touch-action {
  touch-action: auto !important; /* Permite el desplazamiento táctil */
  user-select: auto !important; /* Permite la selección de usuario */
}