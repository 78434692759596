.header {
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #c4c4c4;
}

.table-row:hover {
  background-color: #E3E3E3;
}

.table-cell {
  min-height: 70px;
  width: 150px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &-big {
    width: 200px;
  }
}

.contactsTable {
  max-height: 250px;
}
