.videoPreview {
  height: 200px;
  border-radius: 15px;
  padding: 5px;
  object-fit: cover;
}

.videoEditor {
  width: 500px;
  max-height: 500px;
  object-fit: cover;
  border-radius: 15px;
}
