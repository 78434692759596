#login,
#register {
  .bg {
    background-image: url(https://asset.wembii.ar/authentication-background.jpg);
    background-repeat: no-repeat, repeat;
    background-size: cover;
    background-position: center;
        background-attachment: fixed;

  }
}

#registerEntel {
  .bg {
    background-image: url(https://asset.wembii.ar/authentication-entel.png);
    background-repeat: no-repeat, repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
}

.PhoneInputInput {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #b8e5ff;
    outline: 0;
  }
}
