.mention-input {
  &__input {
    padding: 9px;
    border-radius: 5px;
    border: 1px solid #ced4da;

    &:focus-visible {
      border-color: #b8e5ff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(56,182,255,0.25);
    }
  }

  &__highliter {
    padding: 9;
    border: 1px solid transparent;
  }

  &__control {
    border-radius: 5px;
  }

  &__suggestions {
    &__list {
      background-color: white;
      border: 1px solid rgba(0,0,0,0.15);
      font-size: 14;
    }

    &__item {
      padding: 5px 15px;
      border-bottom: 1px solid rgba(0,0,0,0.15);

      &--focused {
        background-color: #cee4e5;
      }
    }
  }
}

.PhoneInput input:disabled {
  background-color: #e9ecef;
}