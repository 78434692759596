.sideModal {
  position: fixed;
  width: 600px;
  height: 85%;
  top: 115px;
  margin: 10px;
  z-index: 100;
  &Right {
    @extend .sideModal;
    right: 35px;
  }
  &Left {
    @extend .sideModal;
    left: auto;
  }

  $small: 645px;
  $small1: 501px;


  @media screen and (max-width: $small) {
    position: fixed;
    width: 450px;
    height: 80%;
    top: 115px;
    margin: -1px;
    z-index: 100;
  }
  @media screen and (max-width: $small1) {
    position: fixed;
    width: 300px;
    height: 80%;
    top: 115px;
    margin: -1px;
    z-index: 100;
  }
}

.sideModalHidden {
  transition: all 100ms;
  display: none !important;
}

.fullScreenModal{
  top: 100px;
  width: 85%;
  right: 35px;
  margin-left: 50px;
}

.sideModalOn {
  transition: all 700ms ease-out;
  pointer-events: all;
  transform: translateX(0);
}

.sideModalOff {
  transition: all 150ms ease-in;
  pointer-events: none;
  &Right {
    @extend .sideModalOff;
    transform: translateX(110%);
  }
  &Left {
    @extend .sideModalOff;
    transform: translateX(-200%);
  }
}
