.pointer {
  cursor: pointer;
}

.line-break-anywhere {
  line-break: anywhere;
}
.w-fit {
  width: fit-content;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dot {
  border-radius: 50%;
  margin: 10px;
  &-10 {
    @extend .dot;
    width: 10px;
    height: 10px;
  }
}

.flex-grow-1 {
  flex-grow: 1;
}

.left {
  &-50 {
    left: 50%;
  }
}

.top {
  &-50 {
    top: 50%;
  }
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.right-9 {
  right: 9px;
}

.left-9 {
  left: 9px;
}

.mt-6 {
  margin-top: 6px;
}

.float-top-right {
  top: 5px;
  right: 5px;
}

.float-right-corner {
  top: -5px;
  right: -5px;
}

.opacity-4 {
  opacity: 0.25;
}

.z-index-1 {
  z-index: 1;
}

.z-index-5 {
  z-index: 5;
}

.z-index-50 {
  z-index: 50;
}

.font-size-2 {
  font-size: 20px;
}

.pre-wrap {
  white-space: pre-wrap;
}

.text-underline {
  text-decoration: underline;
}

.text-line-through {
  text-decoration: line-through;
}

.shadow-hover:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3) !important;
}

.object-fit-cover {
  object-fit: cover;
}

.position-fixed {
  position: fixed;
}

.position-absolute-center {
  position: absolute;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -80px;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.text-primary-on-hover:hover {
  color: $primary;
}

.text-bold-on-hover:hover {
  font-weight: bold;
}

.bg-black-transparent {
  background-color: rgba(0, 0, 0, 0.6);
}

.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.6);
}

.bg-notification {
  background-color: $primary;
  color: white;
}

.left-0 {
  left: 0;
}

.showed-on-hover {
  display: none;
}
.show-on-hover:hover {
  .hide-on-hover {
    display: none;
  }
  .showed-on-hover {
    display: inline;
  }
}

.indicator {
  max-width: 15px;
  min-width: 15px;
  max-height: 15px;
  min-height: 15px;
  border-radius: 50%;
}
.indicatorGreen {
  background-color: $success;
}
.indicatorYellow {
  background-color: $info;
}
.indicatorRed {
  background-color: $danger;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.truncate-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.no-border {
  border: none;
}

.gradient-event {
  background: linear-gradient(
    90deg,
    rgba(56, 182, 255, 1) 45%,
    rgba(52, 168, 83, 1) 85%
  );
}

.google-event {
  background-color: #34a853;
}

.wembii-event {
  background-color: #0d4b7e;
}

.fs-12 {
  font-size: 12px;
}

.fs-15 {
  font-size: 15px;
}
.tooltip-wembii {
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 50;
  background-color: white;
  color: grey;
  padding: 8px;
}
