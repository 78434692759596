// Variables
$hero-height: 300px;
$card-radius: 12px;
$transition-smooth: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

// Mixins
@mixin glass-effect {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

@mixin hover-lift {
  transition: $transition-smooth;
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  }
}

.home-container {
  min-height: 100%;
  min-width: 100%;
  background: rgb(248, 249, 255);
  color: #1a1a1a;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  // Hero Section
  .hero-section {
    min-height: $hero-height;
    width: 100%;
    padding: 1.5rem;
    position: relative;
    overflow: hidden;
    background: white;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }

    .hero-content {
      position: relative;
      z-index: 1;
      max-width: 1fr;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 2rem;
    }

    .user-welcome {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      .hero-avatar {
        width: 80px;
        height: 80px;
        border: 3px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
      }

      .welcome-text {
        .text-gradient {
          font-size: 2.5rem;
          background: linear-gradient(135deg, #fff 0%, #e2e8f0 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .notification-pill {
      @include glass-effect;
      padding: 0.75rem 1.5rem;
      border-radius: 50px;
      display: flex;
      align-items: center;
      gap: 1rem;
      cursor: pointer;
      transition: $transition-smooth;

      &:hover {
        transform: scale(1.05);
      }

      .notification-icon {
        font-size: 1.25rem;
        color: #0b65c2;
      }

      .notification-count {
        background: #ef4444;
        color: #fff;
        padding: 0.25rem 0.75rem;
        border-radius: 50px;
        font-weight: bold;
      }
    }
  }

  // Dashboard Content
  .dashboard-content {
    max-width: 1200px;
    width: 100%;
    margin: -80px auto 0;
    padding: 0 1.5rem 1.5rem;
    position: relative;
    z-index: 2;
    flex: 1;

    .section-title {
      color: #1a1a1a;
      margin-bottom: 1.5rem;
      font-weight: 600;
      font-size: 1.5rem;
    }

    // Stats Grid
    .stats-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 1.5rem;
      margin-bottom: 3rem;

      .stat-card {
        @include glass-effect;
        border-radius: $card-radius;
        padding: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;


        &.gradient-primary {
          background: linear-gradient(135deg, #0b65c2 0%, #0b65c2 100%);
          color: white;
        }

        &.gradient-success {
          background: linear-gradient(135deg, #0b65c2 0%, #0b65c2 100%);
          color: white;
        }

        .stat-icon {
          font-size: 2rem;
          opacity: 0.9;
        }

        .stat-info {
          text-align: center;

          h3 {
            font-size: 2.5rem;
            font-weight: bold;
            margin: 0;
          }

          p {
            margin: 0;
            opacity: 0.9;
            font-size: 1rem;
          }
        }

        .stat-trend {
          font-size: 1.5rem;
          &.up {
            color: white;
          }
          &.down {
            color: #ef4444;
          }
        }
      }
    }

    // Actions Grid
    .actions-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1.5rem;
      margin-bottom: 3rem;

      .action-card {
        position: relative;
        border-radius: $card-radius;
        overflow: hidden;
        min-height: 160px;
        text-decoration: none;
        @include hover-lift;

        .action-content {
          position: relative;
          z-index: 2;
          padding-top: 2.5rem;
          color: #fff;
          text-align: center;

          .action-icon {
            font-size: 2rem;
            margin-bottom: 1rem;
          }

          h3 {
            font-size: 1.1rem;
            margin: 0;
            font-weight: 500;
          }
        }

        .action-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.1);
          z-index: 1;
        }
      }
      .action-card action-card-disabled {
        background-color: gray;
      }
    }

    // Plan Section
    .plan-section {
      @include glass-effect;
      border-radius: $card-radius;
      padding: 2rem;
      margin-bottom: 3rem;

      .plan-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        .plan-badge {
          background: linear-gradient(135deg, #0b65c2 0%, #0b65c2 100%);
          color: white;
          padding: 1rem 2rem;
          border-radius: 50px;
          font-weight: bold;
          font-size: 1.1rem;
        }
      }

      .features-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 2rem;
        margin-bottom: 3rem;

        .feature-card {
          border-radius: $card-radius;
          padding: 2rem;
          color: #fff;
          @include hover-lift;

          .feature-icon {
            font-size: 2.5rem;
            margin-bottom: 1.5rem;
          }

          .feature-content {
            h4 {
              font-size: 1.25rem;
              margin: 0 0 0.75rem;
            }

            p {
              margin: 0;
              opacity: 0.9;
              font-size: 1.1rem;
            }
          }
        }
      }

      .plan-actions {
        display: flex;
        gap: 1.5rem;
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    // Social Section
    .social-section {
      text-align: center;
      margin-bottom: 4rem;

      .social-grid {
        display: grid;
        grid-template-columns: repeat(4, 100px);
        gap: 2rem;
        justify-content: center;
        margin: 0 auto 3rem;

        .social-item {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 2rem;
          color: #fff;
          transition: $transition-smooth;

          &:hover {
            transform: scale(1.1) rotate(5deg);
          }

          &.whatsapp {
            background: linear-gradient(135deg, #25d366 0%, #128c7e 100%);
          }
          &.facebook {
            background: linear-gradient(135deg, #1877f2 0%, #0d5ab9 100%);
          }
          &.instagram {
            background: linear-gradient(
              45deg,
              #f09433 0%,
              #e6683c 25%,
              #dc2743 50%,
              #cc2366 75%,
              #bc1888 100%
            );
          }
          &.linkedin {
            background: linear-gradient(135deg, #0b65c2 0%, #0b65c2 100%);
          }
        }
      }
    }
  }

  // Buttons
  .btn-glow {
    background: linear-gradient(135deg, #0b65c2 0%, #0b65c2 100%);
    color: #fff;
    padding: 1rem 2.5rem;
    border-radius: 50px;
    border: none;
    font-weight: 600;
    font-size: 1.1rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: $transition-smooth;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 8px 30px rgba(79, 70, 229, 0.6);
      color: #fff;
    }
  }

  .btn-outline-glow {
    @extend .btn-glow;
    background: transparent;
    border: 2px solid #0b65c2;
    color: #0b65c2;
    box-shadow: none;

    &:hover {
      background: rgba(79, 70, 229, 0.1);
      box-shadow: none;
      color: #0b65c2;
    }
  }

  // Footer
  .app-footer {
    width: 100%;
    background: #f8fafc;
    padding: 2rem 0;
    margin-top: auto;
    border-top: 1px solid #e2e8f0;

    .footer-content {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
      padding: 0 2rem;

      .copyright {
        margin: 0;
        color: #64748b;
        font-size: 1.1rem;
      }

      .help-link {
        color: #0b65c2;
        text-decoration: none;
        font-size: 1.1rem;
        transition: $transition-smooth;

        &:hover {
          color: #0b65c2;
        }
      }
    }
  }
}

// Responsive Adjustments
@media (max-width: 768px) {
  .home-container {
    .hero-section {
      min-height: auto;
      padding: 4rem 2rem;

      .hero-content {
        flex-direction: column;
        text-align: center;
        gap: 2rem;
        padding-top: 0;

        .user-welcome {
          flex-direction: column;
          gap: 1.5rem;

          .welcome-text .text-gradient {
            font-size: 2.5rem;
          }
        }
      }
    }

    .dashboard-content {
      margin-top: -50px;
      padding: 0 1rem 2rem;

      .stats-grid,
      .actions-grid,
      .features-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
      }

      .plan-section {
        padding: 2rem;

        .plan-header {
          flex-direction: column;
          text-align: center;
          gap: 1rem;
        }
      }

      .social-grid {
        grid-template-columns: repeat(2, 100px);
        gap: 1rem;
      }
    }

    .app-footer {
      .footer-content {
        flex-direction: column;
        text-align: center;
        padding: 0 1rem;
      }
    }
  }
}
