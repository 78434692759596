/* width */
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  @extend .neu-in;
  margin: 30px 4px;
  padding: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @extend .neu-out;
  margin: 2px;
  background: $primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.hide-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar { 
    width: 0 !important
  }
}