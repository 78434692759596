.panel-list {
  width: 300px;
  max-height: calc(100vh - 250px);

  .nav-item {
    list-style: none;
    a {
      color: black;
    }
  }

  .list-body {
    overflow-y: auto;
    
    .card-panel {
      background-color: #FFF;
    }
  }
}

.labelsExpanded {
  transition: all 300ms;
  max-width: fit-content;
  max-height: fit-content;
}

.labelsCollaped {
  transition: all 200ms;
  max-width: 10px;
  max-height: 5px;
}

.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px;
}

.cards-drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px 5px 5px 5px;
}

.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg)
}

.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg)
}

.card-selected {
  background-color: rgb(172, 225, 255) !important;
}