.panelFilePreview {
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;

  .fileButtons {
    position: absolute;
    top: 0;
    justify-content: center;
    align-items: center;
    display: none;
  }

  .filePreview {
    height: 90px;
    width: 90px;
    border-radius: 15px;
    object-fit: cover;

  }
  
  &.fileError {
    img,
    .filePreview {
      opacity: 0.3;
    }
  }

  &:hover {
    img,
    .filePreview {
      opacity: 0.3;
    }

    .fileButtons {
      display: flex;
    }
  }
}

.total-container {
  -webkit-transition: max-height 1s ease;
  -moz-transition: max-height 1s ease;
  -o-transition: max-height 1s ease;
  transition: max-height 1s ease;
  max-height: 50px;
  
  .panel-2 {
    display: none;
  }

  &:hover {
    max-height: 1000px;

    .panel-1 {
      display: none;
    }

    .panel-2 {
      display: inherit;
    }
  }
}