.facebookPreview {
  &-container {
    width: 400px;
    margin: auto;
    .nav-item {
      list-style: none;
      a {
        color: black;
      }
    }
  }
  &-header {
    padding: 5px;
    color: #141823;
    font-size: 14px;
    &-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  &-image {
    width: 100%;
    object-fit: contain;
  }
  &-description {
    color: #141823;
    font-size: 13px;
    padding: 5px;
    margin-bottom: 5px;
  }
  &-footer {
    width: 100%;
    object-fit: fill;
  }
}
