.tiktokPreview-container {
  background-color: #121212;
  color: #ffffff;
  margin: 0 auto;

  position: relative;
  overflow: hidden;

  .tiktok-preview-wrapper {
    padding: 0;
    height: 100%;
    position: relative;
  }

  .tiktok-preview-header {
    padding: 2px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }

  .tiktok-preview-avatar {
    width: 48px;
    height: 48px;
    margin-right: 12px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 2px solid #ffffff;
      border-radius: 50%;
    }
  }

  .tiktok-preview-user-info {
    flex: 1;
    overflow: hidden;
  }

  .tiktok-preview-username {
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 8px;

    .verified-badge {
      background-color: #20d5ec;
      color: #ffffff;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
    }

    &:after {
      content: "Follow";
      font-size: 14px;
      color: #fe2c55;
      margin-left: auto;
      font-weight: 500;
    }
  }

  .tiktok-preview-caption {
    font-size: 14px;
    line-height: 1.4;
    opacity: 0.9;
    max-height: 80px;
    overflow: hidden;
    word-break: break-word;

    span {
      font-weight: 500;
    }
  }

  .tiktok-preview-content {
    position: relative;
    background-color: #000000;
    height: 100%;
    min-height: 600px;
    overflow: hidden;

    .tiktok-preview-media {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .music-info {
    position: absolute;
    bottom: 80px;
    left: 16px;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;

    .music-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }

  .tiktok-preview-sidebar {
    position: absolute;
    bottom: 80px;
    right: 8px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .action-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
    }

    .action-icon {
      width: 26px;
      height: 26px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      .fa-heart,
      .fa-comment,
      .fa-bookmark,
      .fa-share {
        color: #ffffff;
        font-size: 24px;
      }
    }

    .action-count {
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .music-disc {
    position: absolute;
    bottom: 200px;
    right: 8px;
    z-index: 2;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    padding: 2px;
    animation: rotate 4s linear infinite;

    .disc-inner {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #ffffff;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.background-drop{
    background: black;
    opacity: 0.7;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
