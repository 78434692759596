.textEditor {
  .ql-toolbar.ql-snow {
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  .ql-editor {
    height: 250px;
  }
}
