@import "./ui/styles/Custom.scss";
@import "./ui/styles/Auth.scss";
@import "./ui/styles/Board.scss";
@import "./ui/styles/Separator.scss";
@import "./ui/styles/Layout.scss";
@import "./ui/styles/Neu.scss";
@import "./ui/styles/SideModal.scss";
@import "./ui/styles/homeBackground.scss";
@import "./ui/styles/Backdrop.scss";
@import "./ui/styles/SocialCard.scss";
@import "./ui/styles/MarketCard.scss";
@import "./ui/styles/Utilities.scss";
@import "./ui/styles/LoadingSpinner.scss";
@import "./ui/styles/SocialGrid.scss";
@import "./ui/styles/SocialProvider.scss";
@import "./ui/styles/Inputs.scss";
@import "./ui/styles/TextEditor.scss";
@import "./ui/styles/MiniModal.scss";
@import "./ui/styles/Panel.scss";
@import "./ui/styles/ScrollBar.scss";
@import "./ui/styles/PostImage.scss";
@import "./ui/styles/PostVideo.scss";
@import "./ui/styles/Email.scss";
@import "./ui/styles/Monitor.scss";
@import "./ui/styles/Canva.scss";
@import "./ui/styles/Tables.scss";
@import "./ui/styles/CompanySelector.scss";
@import "./ui/styles/SideBar.scss";
@import "./ui/styles/Calendar.scss";
@import "./ui/styles/Wysiwyg.scss";
//previews
@import "./ui/styles/FacebookPreview.scss";
@import "./ui/styles/TwitterPreview.scss";
@import "./ui/styles/LinkedInPreview.scss";
@import "./ui/styles/InstagramPreview.scss";
@import "./ui/styles/ThemeV2.scss";
@import "./ui/styles/Home.scss";
@import "./ui/styles/TiktokPostPreview.scss";

.videsk-top-container {
  z-index: 1 !important;
}
