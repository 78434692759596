.neu {
  border: none;
  border-radius: 20px;
  background: #ffffff;
  &-in {
    @extend .neu;
    box-shadow: inset 9px 9px 23px #ededed, inset -9px -9px 23px #ffffff;
  }
  &-out {
    @extend .neu;
    box-shadow: 10px 10px 20px #c9c9c9, -10px -10px 20px #ffffff;
  }
  &-fit {
    width: fit-content;
    height: fit-content;
  }
}
