.monitor {
  text-align: center;
  border-radius: 9px;
  max-width: 200px;
  width: 200px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
}
